import LogoMain from "../../assets/imgs/logo-main.png"
import IconOffer from "../../assets/icons/icon-offer.png"
import IconUser from "../../assets/icons/icon-users.png"
import IconLogout from "../../assets/icons/icon-logout.png"
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LOGOUT_USER } from "../../constants/types"
import { logoutAdmin } from '../../services/requests';
import "./Sidebar.css"

export default function Layout({ children }) {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    async function handleLogoutClick() {
        await logoutAdmin();
        dispatch({ type: LOGOUT_USER });
        navigate('/')
    }

    function handleOffersClick() {
        navigate('/admin/offers')
    }

    function handleUsersClick() {
        navigate('/admin/users')
    }

    return (
    <>
<div class="container-fluid website__background">
    <div class="row flex-nowrap">
        <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark sidebar__container">

            <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <div className="sidebar__logo-container">
                    <img src={LogoMain} style={{width: '119px'}} 
                        className="sidebar__logo" alt="" />
                </div>
                <hr className="sidebar__hr"/>

                <ul class="nav nav-pills flex-column mb-0 align-items-center align-items-sm-start" 
                    id="menu"
                    style={{backgroundColor: '#2c2f1f', width:'100%'}}>
                    <li class="nav-item">
                        <img className="sidebar__offers-icon" src={IconOffer} alt=""/>
                        <span class="ms-1 d-none d-sm-inline sidebar__link" 
                            onClick={() => handleOffersClick()}>Special Offers</span>                        
                    </li>
                </ul>
                <p />

                <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" 
                    id="menu"
                    style={{backgroundColor: '#2c2f1f', width:'100%'}}>
                    <li class="nav-item">
                        <img className="sidebar__offers-icon" src={IconUser} alt=""/>
                        <span class="ms-1 d-none d-sm-inline sidebar__link" 
                            onClick={() => handleUsersClick()}>App Users</span>                        
                    </li>
                </ul>

                <hr className="sidebar__hr"/>
                <div class="pb-4">
                <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li class="nav-item sidebar__logout-link">    
                        <img className="sidebar__offers-icon" src={IconLogout} alt=""/>
                        <span class="ms-1 d-none d-sm-inline sidebar__link" 
                            onClick={() => handleLogoutClick()}>Log out</span>                        
                    </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col py-3">
        {children}
        </div>
    </div>
</div>
    </>
  )
}
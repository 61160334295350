import React, {useState, useEffect} from "react";
import './Login.css';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {makeRequest, getOffersList} from "../../services/requests"
import {getErrorMessage} from "../../services/utils";
import {LOGIN_USER} from "../../constants/types";
import LogoMain from "../../assets/imgs/logo-main.png"
import IconEmail from "../../assets/icons/input-icon-email.png"
import IconPassword from "../../assets/icons/input-icon-password.png"
import IconEye from "../../assets/icons/input-icon-eye.png"
import { getToken } from '../../services/token';

const Login = () => {

    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [inputs, setInput] = useState({email: "", password: ""});
    const navigate = useNavigate()
    const inputChange = (e) => {
        setInput({...inputs, [e.target.name]: e.target.value});
    }
    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword)
    }

    const handleLoginFormSubmit = async (event) => {
        event.preventDefault();
        if (!isLoading) {
            setIsLoading(true);
            const resp = await makeRequest('admin/login', inputs, 'POST', false)
            if (resp.token) {
                dispatch({
                    type: LOGIN_USER, payload: {
                        token: resp.token,
                    }
                });
                navigation('/admin/offers');
            }

            if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (getToken()) { 
            getOffersList(1).then((results) => {
                if (results?.message !== "Unauthenticated.") {
                    navigate("/admin/offers") 
                }
            });
        }
        document.title = 'Eason Loyal App - Login';
        // eslint-disable-next-line
    }, []);

    return <>
<div className="login__background">

<div className="div-center">
  <div className="content"style={{textAlign:'center'}}>
    <img src={LogoMain} style={{width: '200px'}} className="login__logo" alt=""/>

    {errorMessage ? 
    <>
    <div class="alert alert-danger" role="alert">
        {errorMessage}
    </div>
    </> : ''}
    
    <form onSubmit={handleLoginFormSubmit}>
        <div className="form-group">
            <div className="login_input-wrapper">
                <div className="login_input-icon">
                    <img src={IconEmail} style={{width:'14px', height:'12px'}} alt=""/>
                </div>
                <input onChange={inputChange} 
                    type="email" 
                    className="form-control login_input" 
                    id="email" 
                    name="email" 
                    placeholder="Email" />
            </div>  
        </div>

        <div className="form-group">
            <div className="login_input-wrapper">
                <div className="login_input-icon">
                    <img src={IconPassword} style={{width:'16px', height:'16px'}} alt=""/>
                </div>
                <input onChange={inputChange} 
                    type={showPassword ? 'text' : 'password'}
                    className="form-control login_input" 
                    name="password" 
                    id="exampleInputPassword1" 
                    placeholder="Password" />

                <div className="login_input-icon-after">
                    <img onClick={() => handleShowPasswordClick()} src={IconEye} style={{width:'16px', height:'10px'}} alt=""/>
                </div>                    
            </div>
        </div>

        <button type="submit" className="btn btn-primary login__button">Sign In</button>
    </form>
  </div>
</div>
</div>
    </>
}

export default Login;

import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import '../../components/Website/Website.css'
import './css/OfferForm.css'
import {
    getOffer,
    addOffer,
    updateOffer
  } from '../../services/requests'
import Layout from '../../components/Website/Layout'
import {useDropzone} from 'react-dropzone';
import IconPlus from '../../assets/icons/icon-plus.png'
import { getToken } from '../../services/token';

const OfferForm = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false);
    const [offer, setOffer] = useState(null);
    const [errorMessage, setErrorMessage] = useState(false);

    const defaultTerms = "These are the default terms and conditions for a special offer."

    const [fileMain, setFileMain] = useState(); 
    const [fileThumb, setFileThumb] = useState();
    const { getRootProps:getRootPropsMain, getInputProps:getInputPropsMain  } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFile) => {
            setFileMain(
                Object.assign(acceptedFile[0], {
                    preview: URL.createObjectURL(acceptedFile[0]),
                }),
            );
        },
    });   
    const { getRootProps:getRootPropsThumb, getInputProps:getInputPropsThumb  } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFile) => {
            setFileThumb(
                Object.assign(acceptedFile[0], {
                    preview: URL.createObjectURL(acceptedFile[0]),
                }),
            );
        },
    });

    var mainImageUrl = fileMain?.preview;
    var thumbImageUrl = fileThumb?.preview;

    const dropzoneStyleMain = {
      backgroundImage: "url(" + mainImageUrl + ")",
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'    
    }

    const dropzoneStyleThumb = {
      backgroundImage: "url(" + thumbImageUrl + ")",
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'    
    }

    const loadData = async () => {
        if (!isLoading) {
          setLoading(true);
          if (id) {
            const resp = await getOffer(id);
            setOffer(resp?.offer?.data);  
            setFileMain(
              Object.assign(resp?.offer?.data?.image_main, {
                  preview: resp?.offer?.data?.image_main
              }))
            setFileThumb(
              Object.assign(resp?.offer?.data?.image_thumbnail, {
                  preview: resp?.offer?.data?.image_thumbnail
              }))
          }
          else {
            var newOffer = {
              title: '',
              body: '',
              terms: defaultTerms
            }
            setOffer(newOffer)
          }
          setLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }
        loadData();
        document.title = 'Eason Loyal App - Offer Form';
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const handleImageMainChange = (event) => {
        setFileMain(
          Object.assign(event?.target?.files[0], {
              preview: URL.createObjectURL(event?.target?.files[0]),
          }))
      };

      const handleImageThumbnailChange = (event) => {
        setFileThumb(
          Object.assign(event?.target?.files[0], {
              preview: URL.createObjectURL(event?.target?.files[0]),
          }),
      )};
      const handleCancelClick = () => {
        navigate('/admin/offers')
      }
  
      const handleLoginFormSubmit = async (event) => {
        event.preventDefault();
        var body = {
          title: offer?.title,
          body: offer?.body,
          terms: offer?.terms,
        };
        if (fileMain) {
            body = {...body, image_main: fileMain}
        }
        if (fileThumb) {
          body = {...body, image_thumbnail: fileThumb}
        }

        if (id) {
          try {
            const resp = await updateOffer(id, { ...body, _method: 'PUT' });
            if (resp?.result === 'success') {
                navigate("/admin/offers?updated=true")
            }
            else {              
              if (resp.messages[0]) {                
                var errorMessageAll = ""
                for (const key in resp.messages[0]) {
                  errorMessageAll = errorMessageAll + " " + resp.messages[0][key];
                }  
                setErrorMessage(errorMessageAll)
              }
              else {
                setErrorMessage("There was an error updating the offer.")
              }
            }
          } catch (error) {
            setErrorMessage("There was an error updating the offer.")
          }
        } else {
          const resp = await addOffer(body);
          if (resp?.result === 'success') {
            navigate("/admin/offers?added=true")
          }
          else {
            if (resp.messages[0]) {                
              var errorMessageAllAdd = ""
              for (const key in resp.messages[0]) {
                errorMessageAllAdd = errorMessageAllAdd + " " + resp.messages[0][key];
              }  
              setErrorMessage(errorMessageAllAdd)
            }
            else {
              setErrorMessage("There was an error adding the offer.")
            }
          }
        }
      }
      
      return <>
      <Layout>
        <div className="container">        
            {id ? 
            <>
              <h3 className="website__header">Edit Offer</h3>
              <p className="website__header-subtitle">Update the details of the special offer below. Once saved a push notification will NOT be sent.</p>
            </>
            :
            <>
              <h3 className="website__header">Add new offer</h3> 
              <p className="website__header-subtitle">Enter the details of the special offer below. Once saved this will be sent immediately as a push notification to all users.</p>
            </>
            }

  {errorMessage ? 
    <>
    <div class="alert alert-danger" role="alert">
        {errorMessage}
    </div>
    </> : ''}
        
  <div class="row offer-form__box">
    <div class="col-sm">

<form onSubmit={handleLoginFormSubmit}>
  <div className="form-group">
    <label className="offer-form__label" for="title">Title</label>
    <input type="text" className="form-control offer-form__input" id="title" placeholder="" 
            onChange={(event) => setOffer({ ...offer, title: event?.target?.value })}
            value={offer?.title}
    />
  </div>
  <div class="form-group">
    <label className="offer-form__label" for="body">Offer Details</label>    
    <textarea className="form-control offer-form__input" id="body" name="body"
        onChange={(event) => setOffer({ ...offer, body: event?.target?.value })}
        value={offer?.body}
    ></textarea>
  </div>

  <div className="row">
    <div className="col-6">
      <div className="form-group">
        <label className="offer-form__label" for="imageMain">Feature Graphic</label>

        <div {...getRootPropsMain({className: 'dropzone', refKey: 'main'})} className="offer-form__dropzone" style={dropzoneStyleMain}>
          <input {...getInputPropsMain()} id="imageMain" name="imageMain" onChange={handleImageMainChange} />
          <div className="offer-form__dropzone-text">
            <div>
              <img src={IconPlus} style={{width:'32px', height: '32px'}} alt=""/>
            </div>
            <div>
              Upload
            </div>            
          </div>        
        </div>    

      </div>
    </div>
    <div className="col-6">
      <div className="form-group">
        <label className="offer-form__label" for="imageMain">Thumbnail</label>

        <div {...getRootPropsThumb({className: 'dropzone'})} className="offer-form__dropzone" style={dropzoneStyleThumb}>
          <input {...getInputPropsThumb()} onChange={handleImageThumbnailChange} id="imageThumbnail" name="imageThumbnail"/>
          <div className="offer-form__dropzone-text">
            <div>
              <img src={IconPlus} style={{width:'32px', height: '32px'}} alt=""/>
            </div>
            <div>
              Upload
            </div>            
          </div>        
        </div>    

      </div>
    </div>
  </div>

  <div class="form-group">
    <label className="offer-form__label" for="terms">Terms & Conditions</label>    
    <textarea className="form-control offer-form__input" id="terms" name="terms"
      defaultValue={defaultTerms}
        onChange={(event) => {
            setOffer({ ...offer, terms: event?.target?.value })
          }
        }
        value={offer?.terms}
    ></textarea>
  </div>  
  <button type="submit" className="btn btn-primary offer-form__save-button">Save</button>
  <button type="button" onClick={() => handleCancelClick()} className="btn btn-secondary offer-form__cancel-button">Cancel</button>  
</form>
        
                </div>
            </div>
        </div>
      </Layout>
    </>
}

export default OfferForm;

import React, {useEffect} from "react";
import './css/Privacy.css';
import LogoMain from "../../assets/imgs/logo-main.png"

const Terms = () => {

    useEffect(() => {
        document.title = 'Eason Loyal App - Terms & Conditions';
        // eslint-disable-next-line
    }, []);

    return <>
<div>

<div className="div-center" style={{width:'75%', position: 'relative', textAlign: 'left'}}>
  <div style={{textAlign:'left'}}>
    <div style={{textAlign: 'center', height: '125px'}}>
    <img src={LogoMain} style={{width: '200px', marginTop: '35px'}} className="login__logo" alt=""/>
    </div>

    <h2>
    Terms and Conditions
    </h2>

    <h3>Introduction</h3>
<p>Welcome to the Eason Connect App, a digital platform provided by Easons Limited. This App offers a loyalty program and enhances your shopping experience with Easons. By downloading and using this App, you are entering into a binding contract with Easons Limited, agreeing to abide by these terms and conditions.</p>
<h3>Acceptance of Terms</h3>
<p>By installing, accessing, or using the Eason Connect App, you confirm your acceptance of these terms and conditions. If you do not agree to these terms, you should not use the App. We reserve the right to update or modify these terms at any time without prior notice, and your continued use of the App constitutes acceptance of those changes.</p>
<h3>Account Registration and Management</h3>
<p>You must register an account to access the App's features. You agree to provide accurate and complete information and keep this information updated. You are responsible for maintaining the confidentiality of your account password and for all activities that occur under your account. Easons Limited cannot and will not be liable for any loss or damage arising from your failure to comply with this security obligation.</p>
<h3>Use of the App</h3>
<p>This App is intended for your personal, non-commercial use. You agree not to use the App for any unlawful purpose, to infringe on any third party's rights, or to breach any applicable laws or regulations. Unauthorized use of this App may give rise to a claim for damages and/or be a criminal offense.</p>
<h3>Loyalty Program</h3>
<p>The loyalty program offered through the App allows you to earn points on purchases, which can be redeemed according to the loyalty program rules. Easons Limited reserves the right to change the terms of the loyalty program, including the ways in which points are earned and redeemed, at any time without prior notice.</p>
<h3>Privacy and Data Protection</h3>
<p>We are committed to protecting your privacy. Our Privacy Notice, as detailed, explains how we handle your personal data. By using the App, you consent to such processing and confirm that all data provided by you is accurate.</p>
<h3>Intellectual Property Rights</h3>
<p>All content within the App, including design, text, graphics, logos, and software, is owned by or licensed to Easons Limited. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction, copying, or any use of the content for commercial purposes is strictly prohibited unless you have our explicit written permission.</p>
<h3>Limitation of Liability</h3>
<p>To the fullest extent permitted by applicable law, under no circumstances, including, but not limited to, negligence, shall we be liable to you for any direct, indirect, incidental, special or consequential damages that result from the use of or the inability to use the App, any changes to the App, any material or data sent or received or not sent or received or for any of the above – mentioned damages that result from the use or inability to use the Products.</p>
<h3>Amendments to Terms</h3>
<p>Easons Limited reserves the right to amend these terms and conditions at any time. All amendments to these terms will be posted on the App. Continued use of the App will be deemed to constitute acceptance of the new terms.</p>
<h3>Termination</h3>
<p>Easons Limited may terminate or suspend your access to the App at any time, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
<h3>Applicable Law</h3>
<p>These terms are governed by and construed in accordance with the laws of the Republic of Ireland. You agree to submit to the exclusive jurisdiction of the Irish courts.</p>
<h3>Contact Information</h3>
<p>If you have any queries about these terms, the App, or if you require any assistance, please contact us at <a href="mailto:dpo@easons.com">dpo@easons.com</a>.</p>

  </div>
</div>
</div>
    </>
}

export default Terms;

import axios from 'axios';
import { getToken } from './token';

export const makeRequest = async (url, data = null, method = 'GET', auth = true, asForm = false) => {
    let headers;    
    var urlApiDomain = ""
    
    switch (window.location.origin) {
        case "http://localhost:3000":
            urlApiDomain = "http://localhost:8000/api/"
            break;
        case "https://dev-loyalapp.easons.com":
            urlApiDomain = "https://dev-loyalapp-api.easons.com/api/"
            break;
        case "https://staging-loyalapp.easons.com":
            urlApiDomain = "https://staging-loyalapp-api.easons.com/api/"
            break;
        case "https://loyalapp.easons.com":
            urlApiDomain = "https://loyalapp-api.easons.com/api/"
            break;
        default:
    }

    if (asForm) {
        headers = {};
    } else {
        headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }
    if (auth) {
        headers.Authorization = `Bearer ${getToken()}`;
    }
        
    console.log(headers.Authorization)    

    const params = {
        method: method,
        headers,
    };
    if (method === 'POST' || method === 'PUT') {
        if (asForm) {
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, data[key]);
            }
            params.data = formData;
        } else {
            params.data = JSON.stringify(data);
        }
    }

    params.url = urlApiDomain + url;
    console.log(params.url)

    try {
        const resp = await axios.request(params);
        return resp.data;
    } catch (err) {
        return err.response.data;
    }
};

export const logoutAdmin = async () => {
    return await makeRequest('admin/logout', null, 'POST');
}
export const getOffersList = async (page) => {
    return await makeRequest(`admin/offer?page=${page}`, null, 'GET', true);
}
export const addOffer = async (data) => {
    return await makeRequest(`admin/offer`, data, 'POST', true, true);
}
export const updateOffer = async (id, data) => {
    return await makeRequest(`admin/offer/${id}`, data, 'POST', true, true);
}
export const getOffer = async (id) => {
    return await makeRequest(`admin/offer/${id}`, null, 'GET', true);
}
export const deleteOffer = async (id) => {
    return await makeRequest(`admin/offer/${id}`, null, 'DELETE', true);
}
export const getUsersList = async (page, keyword) => {
    return await makeRequest(`admin/users?page=${page}&keyword=${keyword}`, null, 'GET', true);
}
export const deleteUser = async (id) => {
    return await makeRequest(`admin/user/${id}`, null, 'DELETE', true);
}

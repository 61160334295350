import React, {useState, useEffect} from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './css/OffersList.css'
import moment from "moment";
import IconActions from "../../assets/icons/icon-actions.png"
import IconEdit from "../../assets/icons/icon-edit.png"
import IconDelete from "../../assets/icons/icon-delete.png"
import IconArrowRight from "../../assets/icons/icon-arrow-right.png"
import IconArrowLeft from "../../assets/icons/icon-arrow-left.png"
import IconDeleteConfirmation from "../../assets/icons/icon-delete-confirmation.png"
import '../../components/Website/Website.css'
import {
    getOffersList,
    deleteOffer
  } from '../../services/requests'
import Layout from '../../components/Website/Layout'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getToken } from '../../services/token';

const OffersList = () => {

    const [offerId, setOfferId] = useState();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false);
    const [offers, setOffers] = useState(null);
    const [pageTotal, setPageTotal] = useState(0)
    const loadData = async () => {
        if (!isLoading) {
          setLoading(true);
          var localPage = 1
          if (searchParams.get('page')) {
            localPage = searchParams.get('page')
          }
          // alert(localPage)
          console.log('localPage; ' + localPage)
          const resp = await getOffersList(localPage);
          setOffers(resp?.offers?.data);
          setPageTotal(resp?.offers?.last_page)
          setLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }

        loadData();
        document.title = 'Eason Loyal App - Offers';
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
    const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);

    function handleDelete(id) {
      handleCloseDeleteConfirmation()
      deleteOffer(id).then((results) => {
        loadData();
        searchParams.set('deleted', 'true')
      })
    }

    function handlConfirmDelete(id) {
      setOfferId(id)
      handleShowDeleteConfirmation()
    }
    function handleCreateNewClick() {
      navigate('/admin/offers/add');
    }
    function handleUpdatePage(page) {
      if (isNaN(page)) { 
        page = 2
      }
      searchParams.set('page', page)
      loadData()
    }

    const [state, setState] = useState([])
    var toggleOpen = (index) => {
      var stateLocal = state;
      if (stateLocal[index] === "true") {
        stateLocal[index] = "false"
        loadData()
      }
      else {
        stateLocal = [];
        stateLocal[index] = "true"
      }
      setState( stateLocal );
    }
    var closeAll = (e) => {
      if (e.target.id === "dropdownMenuButton") {
        return
      }
      setState([]);
    }
  
    function truncate(str) {
      return str.length > 50 ? str.substring(0, 7) + "..." : str;
    }

    return <>
      <Layout>
        <div className="container">
            {searchParams.get('added') ? (
            <div className={'alert alert-success'}>The offer has now been saved and sent to all mobile users.</div>
            ) : null}                
            {searchParams.get('updated') ? (
            <div className={'alert alert-success'}>The offer has now been saved.</div>
            ) : null}                
            {searchParams.get('deleted') ? (
            <div className={'alert alert-success'}>The offer has now been deleted.</div>
            ) : null}                

            <Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation}
                aria-labelledby="contained-modal-title-vcenter" centered
              >
              <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto">
                  <img src={IconDeleteConfirmation} style={{width: '50px', height: '50px'}} alt=""/>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body  style={{
                display: "flex",
                justifyContent: "center",
                }}>Are you sure you want to delete this offer?</Modal.Body>
              <Modal.Footer style={{
                display: "flex",
                justifyContent: "center",
                }} className="px-4">
                <Button centered variant="secondary" style={{width: '100px'}} onClick={handleCloseDeleteConfirmation}>
                  No
                </Button>
                <Button centered variant="primary" style={{width: '100px'}} onClick={() => handleDelete(offerId)}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            <button class="btn btn-primary offers__create-button" onClick={() => handleCreateNewClick()}>+ Create New</button>
            <h3 className="website__header">Special offers</h3>
            
            <div className="offers__table-box">
            <table className="offers__table" onClick={(e) => closeAll(e)}>
              <thead>
                <th style={{borderTopLeftRadius: '16px'}}>Title</th>
                <th>Offer Details</th>
                <th>Date</th>
                <th style={{borderTopRightRadius: '16px'}}>Action</th>
              </thead>
              <tbody>

            {offers?.map((offer, index) => {

                var menuClass = `dropdown-menu${state[index]==="true" ? " show" : ""}`;
                return (
                    <>
                    <tr key={offer?.id}>
                      <td>
                        {offer?.title}
                      </td>
                      <td>
                        {truncate(offer?.body)}
                      </td>
                      <td>
                        { moment.utc(offer?.created_at, 'YYYY-MM-DD HH:mm:ss').local()
                          .format("Do MMM Y") }                        
                      </td>
                      <td style={{width: '100px'}}>
                        <img src={IconActions} style={{width: '20px', height: '20px'}} 
                          onClick={() => toggleOpen(index)}
                          className="dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          alt=""
                        />

                        <div className={menuClass} aria-labelledby="dropdownMenuButton" style={{width: '125px'}}>
                          <Link to={`/admin/offers/${offer.id}`} className="dropdown-item">
                            <img src={IconEdit} className="offers__edit-icon" alt=""/> Edit
                          </Link>
                          <hr className="offers_edit-hr" />
                          <Link onClick={() => handlConfirmDelete(offer?.id)} className="dropdown-item">
                            <img src={IconDelete} className="offers__delete-icon" alt=""/> Delete
                          </Link>
                        </div>
                      </td>
                    </tr>                    
                    </>
                );
            })}
            </tbody>
            </table>

            <div style={{backgroundColor: '#fff', width: '100%', height: '30px', 
                    marginTop: '15px',
                    marginBottom: '15px'}}>
                <div style={{float: 'right'}}>

{(!searchParams.get('page') || parseInt(searchParams.get('page')) === 1) ? 
<><div className="pageLink disabled">
  <img src={IconArrowLeft} style={{width: '14px', height: '14px'}} alt="left" />
</div></>
: 
<><div className="pageLink" onClick={() => handleUpdatePage(parseInt(searchParams.get('page')) - 1)}>
  <img src={IconArrowLeft} style={{width: '14px', height: '14px'}} alt="left" />
</div></>}

                {[...Array(pageTotal)].map((x, i) =>
                  <div         
                    onClick={() => handleUpdatePage(i+1)}
                    className={ "pageLink" +                 
                  (((i+1) === parseInt(searchParams.get('page')) ||                    
                    (!searchParams.get('page') && i===0)
                    ) ? ' active' : '')
                }>
                  {(i + 1)}            
                  </div>
                )}

{(pageTotal === 1 || parseInt(searchParams.get('page')) === pageTotal) ? 
<><div className="pageLink disabled">
  <img src={IconArrowRight} style={{width: '14px', height: '14px'}} alt="right" />
</div></>
: 
<><div className="pageLink" onClick={() => handleUpdatePage(parseInt(searchParams.get('page')) + 1)}>
  <img src={IconArrowRight} style={{width: '14px', height: '14px'}} alt="right" />
</div></>}

                </div>                  
              </div>
            </div>

        </div>
      </Layout>
    </>
}

export default OffersList;

import React, {useEffect} from "react";
import './css/Privacy.css';
import LogoMain from "../../assets/imgs/logo-main.png"

const Privacy = () => {

    useEffect(() => {
        document.title = 'Eason Loyal App - Privacy Policy';
        // eslint-disable-next-line
    }, []);

    return <>
<div>

<div className="div-center" style={{width:'75%', position: 'relative', textAlign: 'left'}}>
  <div style={{textAlign:'left'}}>
    <div style={{textAlign: 'center', height: '125px'}}>
    <img src={LogoMain} style={{width: '200px', marginTop: '35px'}} className="login__logo" alt=""/>
    </div>
    
    <h2>
    Privacy Notice
    </h2>
    
    <h3>Who Are We?</h3>
    <p>We are Easons Limited, a private limited company registered in Ireland under company registration no. 678677. Our registered office is at Block 4, First Floor, Swords Business Park, Swords, Co. Dublin, Ireland. We provide a loyalty mobile application service for our customers.</p>

<h3>Data Controller</h3>
<p>For the purposes of the General Data Protection Regulation (the GDPR), the data controller is Easons Limited, a private limited company registered in Ireland. Our company is responsible for, and committed to, the processing of your personal data in a lawful, fair, and transparent manner.</p>

<h3>Data Protection Officer</h3>
<p>Our Data Protection Officer can be contacted by email at <a href="mailto: dpo@easons.com">dpo@easons.com</a>.</p>

<h3>What Personal Information Do We Collect?</h3>
<b>Account Registration and Management</b>
<p>Upon installing and registering on the Eason Connect App, we collect essential personal information necessary to create and manage your user account. This information includes:</p>
<ul>
<li><b>Email Address:</b> Used as a unique identifier for your account and a primary method of communication.</li>
<li><b>First Name and Last Name:</b> Helps us personalize our communication and manage your account.</li>
<li><b>Gender (optional):</b> This optional detail is used for demographic analysis and to tailor our services and offers.</li>
<li><b>Password:</b> We require you to create a password to secure your account and protect your personal information.</li>
</ul>

<h3>Loyalty Program Participation </h3>
<p>To participate in our loyalty program through the App, we handle the following information:</p>
<ul>
<li><b>Loyalty Points Accrual:</b> When you scan the QR code in one of our stores, we record the transaction to accurately award loyalty points to your account.</li>
</ul>

<h3>What Information About You Do We Obtain from Others? </h3>
<p>We do not obtain personal data about you from third parties in relation to this App.</p>

<h3>Why Do We Collect This Information?</h3>
<p><b>User Registration:</b> We collect your information to set up and secure your user profile, allowing for a personalized and protected experience within our system.</p>
<p><b>Loyalty Points:</b> Your data enables us to track and update your loyalty points, ensuring you receive the benefits to which you are entitled when making purchases.</p>
<p><b>Service Enhancement:</b> Collecting this data helps us to understand how you engage with our app, which guides us in making improvements and adding features that enhance your experience.</p>

<h3>Legal Basis for Processing</h3>
<ul>
  <li><b>Your Consent:</b> When you download and register on the app, you provide your consent for us to process your personal data. This is the primary legal basis for us to handle your data, including your contact details and any other information you directly provide.</li>
  <li><b>Contractual Necessity:</b> The processing of your data is necessary for the performance of the contract that you enter into with us when you accept our Terms of Service. This includes managing your account, accurately recording, and updating your loyalty points, and ensuring you can log in to view available promotions.</li>
  <li><b>Legitimate Interests:</b> While we do not send marketing communications outside of the app, we process your data for our legitimate business interests. This encompasses providing you with a user-friendly experience and offering you discounts and the ability to use loyalty points within the app, which necessitates logging in. It allows us to enhance our product offerings and optimize your experience without direct marketing.</li>
</ul>

<p>We ensure that our processing of your data for these legitimate interests does not unfairly go against what is right and best for you. Instead, it's aimed at providing you with access to the full benefits and features of the app, like viewing and using promotional discounts and loyalty points which are part of our value proposition to you as a user.</p>

<h3>Who Do We Share Your Information With?</h3>
<ul>
  <li><b>Technical Support:</b> Your information may be shared with IT service providers who assist us in maintaining the App’s technical functionality, ensuring it operates smoothly and securely for a better user experience. These providers are carefully selected and obligated to protect your data.</li>
  <li><b>Operational Services:</b> We may share information with companies that provide essential services such as data hosting or maintenance services for our App, which are critical to its operation and your use of it.</li>
  <li><b>Legal Obligations:</b> If we are compelled by law, such as through a subpoena or similar legal process, we may share your information with law enforcement or other government agencies as required.</li>
  <li><b>Protecting Our Rights:</b> We may also disclose your information if it is necessary to enforce our terms and conditions, to ensure the integrity of our services, or to protect the rights, property, or safety of Easons Limited and our users.</li>
</ul>

<h3>How Long Do We Keep Your Information? </h3>
<p>Your information is kept as long as necessary to provide the App services and in accordance with legal, tax, and regulatory requirements.</p>

<h3>What are your rights with respect to you personal data?</h3>
<p>You have the following rights:</p>
<ul>
  <li>The right to access the personal data we hold about you.</li>
  <li>The right to require us to rectify any inaccurate personal data about you without undue delay.</li>
  <li>The right to have us erase any personal data we hold about you in circumstances such as where it is no longer necessary for us to hold the personal data or, in some circumstances, if you have withdrawn your consent to the processing.</li>
  <li>The right to object to us processing personal data about you such as processing for profiling or direct marketing.</li>
  <li>The right to ask us to provide your personal data to you in a portable format or, where technically feasible, for us to port that personal data to another provider provided it does not result in a disclosure of personal data relating to other people.</li>
  <li>The right to request a restriction of the processing of your personal data.</li>
</ul>

<p>Where our processing of your personal data is based on your consent to that processing, you have the right to withdraw that consent at any time but any processing that we have carried out before you withdrew your consent remains lawful.</p>
<p>You may lodge a complaint with your local supervisory authority with respect to our processing of your personal data. The local Supervisory Authority in Ireland is the Data Protection Commission. The website is www.dataprotection.ie.</p>

<h3>Security Statement</h3>
<p>To protect your personal data, we implement robust security measures:</p>
<p><b>Technical Safeguards:</b> Our infrastructure incorporates firewalls and anti-malware defences to guard against cyber threats, and we use encryption to secure sensitive data in transit and at rest.</p>
<p><b>Clean Security Practices:</b> We conduct regular internal reviews to ensure proper access controls are in place and maintain strict oversight on who can interact with your data.</p>
<p><b>Third-Party Due Diligence:</b> We carefully assess all third-party service providers to ensure they employ top-tier security practices before they handle any of your information.</p>
<p>App Center SDK for collecting diagnostics information in case apps crash. This way we will have a stack trace and will be able to more easily identify causes of problems and fix them.</p>

<h3>What will happen if we change our privacy notice?</h3>
<p>This notice may change from time to time, and any changes will be posted on our App and will be effective when posted. Please review this notice each time you use our App or our services. </p>

<h3>Contact Us </h3>
<p>For further information or to exercise your rights, please contact our Data Protection Officer at <a href="mailto: dpo@easons.com">dpo@easons.com</a>.</p>

<h3>Third Parties</h3>
<p>Your data may be shared with third parties as necessary for the provision of the App's services and as detailed in our website's privacy notice. Here is a list of third parties:</p>

<ul>
  <li><b>OneSignal:</b> For push notifications about new special offers</li>
  <li><b>SendGrid:</b> System email notifications (registration email verification, reset password, change email verification).</li>
  <li><b>Snipp:</b> Registering customers and retrieving loyalty card numbers</li>
  <li><b>App Center:</b> App Center SDK for collecting diagnostics information in case apps crash. This way we will have a stack trace and will be able to more easily identify causes of problems and fix them.</li>
</ul>

  </div>
</div>
</div>
    </>
}

export default Privacy;

import React, {useState, useEffect} from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './css/UsersList.css'
import IconClear from "../../assets/icons/icon-clear.png"
import IconArrowRight from "../../assets/icons/icon-arrow-right.png"
import IconArrowLeft from "../../assets/icons/icon-arrow-left.png"
import IconDeleteConfirmation from "../../assets/icons/icon-delete-confirmation.png"
import '../../components/Website/Website.css'
import {
    deleteUser,
    getUsersList
  } from '../../services/requests'
import Layout from '../../components/Website/Layout'
import { getToken } from '../../services/token';
import IconActions from "../../assets/icons/icon-actions.png"
import IconDelete from "../../assets/icons/icon-delete.png"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const UsersList = () => {

    const [userId, setUserId] = useState();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false);
    const [users, setUsers] = useState(null);
    const [pageTotal, setPageTotal] = useState(0)
    const [keyword, setKeyword] = useState('')

    const loadData = async (keywordSupplied) => {
        if (!isLoading) {
          setLoading(true);
          var localPage = 1
          if (searchParams.get('page')) {
            localPage = searchParams.get('page')
          }

          const resp = await getUsersList(localPage, keywordSupplied);
          setUsers(resp?.users?.data);
          setPageTotal(resp?.users?.last_page)
          setLoading(false);
        }
      };
      useEffect(() => {
        if (!getToken()) { navigate("/") }

        loadData('');
        document.title = 'Eason Loyal App - Users';
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    function handleUpdatePage(page) {
      if (isNaN(page)) { 
        page = 2
      }
      searchParams.set('page', page)
      loadData(keyword)
    }
          
    function handleKeywordChange(value) {
        searchParams.set('page', 1)
        loadData(value)
        setKeyword(value)
    }

    function handleClearTextClick() {
        setKeyword('')
        loadData('')
    }

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
    const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);

    function handleDelete(id) {
      handleCloseDeleteConfirmation()
      deleteUser(id).then((results) => {        
        searchParams.set('page', 1)
        searchParams.set('deleted', 'true')
        loadData('');
      })
    }

    function handlConfirmDelete(id) {
      setUserId(id)
      handleShowDeleteConfirmation()
    }

    const [state, setState] = useState([])
    var toggleOpen = (index) => {
      var stateLocal = state;
      if (stateLocal[index] === "true") {
        stateLocal[index] = "false"
        loadData()
      }
      else {
        stateLocal = [];
        stateLocal[index] = "true"
      }
      setState( stateLocal );
    }
    var closeAll = (e) => {
      if (e.target.id === "dropdownMenuButton") {
        return
      }
      setState([]);
    }

    return <>
      <Layout>
        <div className="container">
            
        <div className="form-group">
            <div className="users_input-wrapper">
                <div className="users_input-icon" hidden={!keyword}>
                    <img src={IconClear} style={{width:'20px', height:'20px', cursor:'pointer'}} alt=""
                        onClick={() => handleClearTextClick()}/>
                </div>
                <input
                    onChange={(e) => handleKeywordChange(e.target.value)}
                    className="form-control users_input" 
                    defaultValue={keyword}
                    value={keyword}
                    id="keyword"
                    placeholder="Enter search keyword." />
            </div>  
        </div>

        <Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation}
                aria-labelledby="contained-modal-title-vcenter" centered
              >
              <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto">
                  <img src={IconDeleteConfirmation} style={{width: '50px', height: '50px'}} alt=""/>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body  style={{
                display: "flex",
                justifyContent: "center",
                }}>Are you sure you want to delete this user?</Modal.Body>
              <Modal.Footer style={{
                display: "flex",
                justifyContent: "center",
                }} className="px-4">
                <Button centered variant="secondary" style={{width: '100px'}} onClick={handleCloseDeleteConfirmation}>
                  No
                </Button>
                <Button centered variant="primary" style={{width: '100px'}} onClick={() => handleDelete(userId)}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>


            <h3 className="website__header">App users</h3>
            
            <div className="offers__table-box">
            <table className="offers__table" onClick={(e) => closeAll(e)}>
              <thead>
                <th style={{borderTopLeftRadius: '16px'}}>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Card Number</th>
                <th style={{borderTopRightRadius: '16px'}}>Action</th>
              </thead>
              <tbody>

            {users?.map((user, index) => {

                var menuClass = `dropdown-menu${state[index]==="true" ? " show" : ""}`;
                return (
                    <>
                    <tr key={user?.id}>
                      <td>
                        {user?.first_name}
                      </td>
                      <td>
                        {user?.last_name}
                      </td>
                      <td>
                        {user?.email}
                      </td>
                      <td>
                        {user?.card_number}
                      </td>             
                      <td style={{width: '100px'}}>
                        <img src={IconActions} style={{width: '20px', height: '20px'}} 
                          onClick={() => toggleOpen(index)}
                          className="dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          alt=""
                        />

                        <div className={menuClass} aria-labelledby="dropdownMenuButton" style={{width: '125px'}}>
                          <Link onClick={() => handlConfirmDelete(user?.id)} className="dropdown-item">
                            <img src={IconDelete} className="offers__delete-icon" alt=""/> Delete
                          </Link>
                        </div>
                      </td>

                    </tr>                    
                    </>
                );
            })}
            </tbody>
            </table>

            <div style={{backgroundColor: '#fff', width: '100%', height: '30px', 
                    marginTop: '15px',
                    marginBottom: '15px'}}>
                <div style={{float: 'right'}}>

{(!searchParams.get('page') || parseInt(searchParams.get('page')) === 1) ? 
<><div className="pageLink disabled">
  <img src={IconArrowLeft} style={{width: '14px', height: '14px'}} alt="left" />
</div></>
: 
<><div className="pageLink" onClick={() => handleUpdatePage(parseInt(searchParams.get('page')) - 1)}>
  <img src={IconArrowLeft} style={{width: '14px', height: '14px'}} alt="left" />
</div></>}

                {[...Array(pageTotal)].map((x, i) =>
                  <div         
                    onClick={() => handleUpdatePage(i+1)}
                    className={ "pageLink" +                 
                  (((i+1) === parseInt(searchParams.get('page')) ||                    
                    (!searchParams.get('page') && i===0)
                    ) ? ' active' : '')
                }>
                  {(i + 1)}            
                  </div>
                )}

{(pageTotal === 1 || parseInt(searchParams.get('page')) === pageTotal) ? 
<><div className="pageLink disabled">
  <img src={IconArrowRight} style={{width: '14px', height: '14px'}} alt="right" />
</div></>
: 
<><div className="pageLink" onClick={() => handleUpdatePage(parseInt(searchParams.get('page')) + 1)}>
  <img src={IconArrowRight} style={{width: '14px', height: '14px'}} alt="right" />
</div></>}

                </div>                  
              </div>
            </div>

        </div>
      </Layout>
    </>
}

export default UsersList;

import './App.css';
import Login from "./screens/Login/Login";
import OffersList from "./screens/Offers/OffersList";
import OfferForm from "./screens/Offers/OfferForm";
import UsersList from "./screens/Users/UsersList";
import Privacy from "./screens/Public/Privacy";
import Terms from "./screens/Public/Terms";

import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/terms", element: <Terms /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/admin", element: <OffersList /> },
    { path: "/admin/users", element: <UsersList /> },
    { path: "/admin/offers", element: <OffersList /> },
    { path: "/admin/offers/:id", element: <OfferForm /> },
    { path: "/admin/offers/add", element: <OfferForm /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;